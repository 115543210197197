.allPuzzleMenu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: aliceblue;
  z-index: 1;
  transform: translateY(-100%);
  transition: transform 300ms ease-out;
}
.allPuzzleMenu[aria-hidden="true"] {
  display: none; 
}
.additionalClueBtn {
  display: none;
  background-color: rgba(0,0,0,0.15)
}

.additionalClueBtn:first-child,
.additionalClue + .additionalClueBtn {
  display: inline-block;
}

.additionalClueList {
  padding: 0;
  margin: 0;
  animation-delay: 30s;
  animation-duration: 300ms;
  animation-name: popIn;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

@keyframes popIn {
  from {
    transform: scale(0.7);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.allPuzzleMenu:hover {
  transform: translateY(-10%);
}

.clueSlide {
  position: relative;
  z-index: 2;
}


.clueSlide > * {
  text-align: center;
}

.clueSlide img {
  max-height: 50vh;
  margin: 0 auto;
  display: flex;
  border-radius: 60px;
}

.clueCarouselWrapper {
  height: 100vh;
  width: 100vw;
}

.clueCarouselWrapper .splide {
  height: 100%;
}

.clueCarouselWrapper .splide__pagination > li .is-active {
  opacity: 1;
}

.clueCarouselWrapper .splide__arrows  {
  position: absolute;
  bottom: 40px;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.clueCarouselWrapper .splide__arrow  {
  background-color: #dfdfdf;
}

.clueCarouselWrapper .splide__arrow:hover,
.clueCarouselWrapper .splide__arrow:focus  {
  background-color: lightgreen;
}

.clueCarouselWrapper .splide__arrow:disabled {
  opacity: 0.3;
  border-color: #eee;
  background-color: #fff;
}

.clueCarouselWrapper .splide__pagination  {
  position: absolute;
  bottom: 0;
  height: 40px;
}

.clueCarouselWrapper .splide__track  {
  height: 100%;
}

.clueCarouselWrapper .splide__slide > div {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.3;
  width: 75%;
  transform: translate(-50%, -50%) scale(0.7);
  transition: transform 150ms ease-out, opacity 150ms ease-out; 
}

.clueCarouselWrapper .splide__slide.is-active > div {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.clueCarouselWrapper .splide__arrow--prev {
  transform: rotate(180deg);
}

.clueCarouselWrapper .splide__pagination {
  width: 100%;
  justify-content: space-between;
  padding: 0;
}
.clueCarouselWrapper .splide__pagination > li {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
}

.resultIndicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7) rotate(-90deg);
  font-size: 120px;
  opacity: 0;
  pointer-events: none;
  transition: transform 500ms ease-out;
  border-radius: 500px;
}

.incorrect .resultIndicator {
  opacity: 1;
  transform: translate(-50%, 100%) scale(2.7) rotate(0deg);
}

.correct .resultIndicator {
  opacity: 1;
  transform: translate(-50%, -150%) scale(2.7) rotate(0deg);
}

.statusBar {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: calc(100%);
  padding: 20px;
  color: white;
  font-size: 18px;
  text-shadow: 0 0 5px black;
  /* background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 40%, rgba(0,0,0,0) 100%); */
}
.statusBar span {
  padding-left: 20px;
}
.radioGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.radioWrapper {
  flex: 1;
  min-width: 50%;
}

.radioButton {
  margin: 6px;
  padding: 20px;
  border: 3px solid #000;
  display: inline-block;
  width: calc(100% - 12px);
  border-radius: 60px;
  font-weight: bold;
  background: linear-gradient(130deg, #ffffff 10%, #eeeeee 30%, #ffffff);
  background-size: 120% 150%;
  color: #000;
  transition: 
    border-top-color 300ms ease-out,
    border-left-color 300ms ease-out,
    border-bottom-color 300ms ease-out,
    border-right-color 300ms ease-out,
    background-position 300ms ease-out,
    opacity 150ms ease-out;
  }

input:disabled {
  pointer-events: none;
}
input:disabled + .radioButton {
  pointer-events: none;
  opacity: 0.5;
}


.clueSlide {
  color: #ffffff;
}

.gameOver {
  background-image: url('../images/congrats.jpg');
  background-size: auto 100%;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-color: #080008;
  background-position: center;
}

.finalScore {
  position: fixed;
  bottom: 13%;
  width: 100%;
  text-align: center;
  font-size: 60px;
  color: white;
  text-shadow: 0 0 5px black;
  background-color: rgba(0,0,0,0.7);
}

.splide__slide:before {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: url('../images/stone.png');
  opacity: 0.5;
} 

.radioButton:focus,
.radioButton:hover {
  background-position: top;
}

/* BLUE SILVER */

[data-theme="blue-silver"] .radioButton {
  border-top-color: #fafafa;
  border-left-color: #fafafa;
  border-bottom-color: #999999;
  border-right-color: #999999;
  /* background-image: linear-gradient(130deg, #aaaaaa 10%, #eeeeee 30%, #aaaaaa); */
  background-image: linear-gradient(130deg, rgba(191,200,201,1) 0%, rgba(253,254,255,1) 23%, rgba(238,238,238,1) 27%, rgba(209,211,212,1) 43%, rgba(202,207,209,1) 56%, rgba(210,220,223,1) 61%, rgba(153,170,176,1) 100%);
  /* linear-gradient(130deg, rgba(191,200,201,1) 0%, rgba(253,254,255,1) 23%, rgba(238,238,238,1) 27%, rgba(209,211,212,1) 43%, rgba(202,207,209,1) 56%, rgba(210,220,223,1) 61%, rgba(153,170,176,1) 100%); */
}
[data-theme="blue-silver"] .radioButton:focus,
[data-theme="blue-silver"] .radioButton:hover {
  border-top-color: #999999;
  border-left-color: #999999;
  border-bottom-color: #fafafa;
  border-right-color: #fafafa;
}
[data-theme="blue-silver"].splide__slide {
  background-color: #05053f;
}
[data-theme="blue-silver"].splide__slide:before {
  opacity: 0.2;
}

/* SILVER BLUE */

[data-theme="silver-blue"] .radioButton {
  border-top-color: #1c5abe;
  border-left-color: #1c5abe;
  border-bottom-color: #0e0e4d;
  border-right-color: #0e0e4d;
  background-color: #151575;
  background-image: none;
  /* background-image: linear-gradient(130deg, #151575 10%, #1c5abe 30%, #00005c); */
  color: white;
}
[data-theme="silver-blue"] .radioButton:focus,
[data-theme="silver-blue"] .radioButton:hover {
  border-top-color: #0e0e4d;
  border-left-color: #0e0e4d;
  border-bottom-color: #1c5abe;
  border-right-color: #1c5abe;
}
[data-theme="silver-blue"].splide__slide {
  background-color: #dedede;
  /* background: linear-gradient(130deg, #aaaaaa 10%, #eeeeee 30%, #aaaaaa); */
  background-image: linear-gradient(130deg, rgba(191,200,201,1) 0%, rgba(253,254,255,1) 23%, rgba(238,238,238,1) 27%, rgba(209,211,212,1) 43%, rgba(202,207,209,1) 56%, rgba(210,220,223,1) 61%, rgba(153,170,176,1) 100%);
}
[data-theme="silver-blue"].splide__slide:before {
  /* background-image: url('../images/paper.webp'); */
  opacity: 0;
  background-size: 100%;
}
[data-theme="silver-blue"] .clueSlide h2 {
  color: black
}

/* RED GREEN */

[data-theme="red-green"] .radioButton {    border-top-color: #d8f3d8;
  border-top-color: #d8f3d8;
  border-left-color: #d8f3d8;
  border-bottom-color: #00aa00;
  border-right-color: #00aa00;
  background-image: linear-gradient(130deg, #7be67b 10%, #a1ffa1 30%, #4fd64f);
}
[data-theme="red-green"] .radioButton:focus,
[data-theme="red-green"] .radioButton:hover {
  border-top-color: #00aa00;
  border-left-color: #00aa00;
  border-bottom-color: #d8f3d8;
  border-right-color: #d8f3d8;
}
[data-theme="red-green"].splide__slide {
  background-color: #990000;
  /* background: linear-gradient(130deg, #aa0000 10%, #ff0000 30%, #bb0000); */
}

/* GREEN RED */

[data-theme="green-red"] .radioButton {
  border-top-color: #fe9393;
  border-left-color: #fe9393;
  border-bottom-color: #bf0909;
  border-right-color: #bf0909;
  background-image: linear-gradient(130deg, #f11818 10%, #ff5b5b 30%, #e41414);
}
[data-theme="green-red"] .radioButton:focus,
[data-theme="green-red"] .radioButton:hover {
  border-top-color: #bf0909;
  border-left-color: #bf0909;
  border-bottom-color: #fe9393;
  border-right-color: #fe9393;
}
[data-theme="green-red"].splide__slide {
  background-color: #228122;
  /* background: linear-gradient(130deg, #00aa00 10%, #00ff00 30%, #00aa00); */
}
[data-theme="green-red"].splide__slide:before {
  background-image: url('../images/material.png');
  opacity: 0.5;
}

/* GOLD MAROON */

[data-theme="gold-maroon"] .radioButton {
  /* border-top-color: #a71d1d;
  border-left-color: #a71d1d;
  border-bottom-color: #350303;
  border-right-color: #350303;
  background-color: #4d031f; */
  background-image: linear-gradient(130deg, #650101 10%, #8f1515 30%, #3f0303);

  border-top-color: #c10bb6;
  border-left-color: #c10bb6;
  border-bottom-color: #1d001b;
  border-right-color: #1d001b;
  background-color: #580453;
  background-image: none;
  color: white;
}
[data-theme="gold-maroon"] .radioButton:focus,
[data-theme="gold-maroon"] .radioButton:hover {
  border-top-color: #1d001b;
  border-left-color: #1d001b;
  border-bottom-color: #c10bb6;
  border-right-color: #c10bb6;
}
[data-theme="gold-maroon"].splide__slide {
  /* background-color: #ffd057; */
  background-image: linear-gradient(130deg, rgba(233,178,17,1) 0%, rgba(245,245,200,1) 23%, rgba(254,247,225,1) 27%, rgba(238,230,192,1) 43%, rgba(244,214,141,1) 56%, rgba(236,218,157,1) 61%, rgba(212,178,57,1) 100%);
  /* background: linear-gradient(130deg, #ddd057 10%, #ffd057 30%, #ddd057); */
  background-image: linear-gradient(130deg, rgba(203,152,5,1) 0%, rgba(255,255,180,1) 23%, rgba(251,231,167,1) 27%, rgba(238,208,63,1) 43%, rgba(244,214,141,1) 56%, rgba(252,223,122,1) 61%, rgba(212,178,57,1) 100%);
}
[data-theme="gold-maroon"].splide__slide:before {
  /* background-image: url('../images/paper.webp'); */
  opacity: 0;
  background-size: 100%;
}
[data-theme="gold-maroon"] .clueSlide h2 {
  color: black
}

/* MAROON GOLD */

[data-theme="maroon-gold"] .radioButton {
  border-top-color: #ffe0ad;
  border-left-color: #ffe0ad;
  border-bottom-color: #c6814a;
  border-right-color: #c6814a;
  background-image: linear-gradient(130deg, #e4a946 10%, #ffd057 30%, #d19229 );
  background-image: linear-gradient(130deg, #e4a946 10%, #f4e5cb 25%, #ffc761 30%, #b97f1f );
  background-image: linear-gradient(130deg, rgba(233,178,17,1) 0%, rgba(245,245,200,1) 23%, rgba(254,247,225,1) 27%, rgba(238,230,192,1) 43%, rgba(244,214,141,1) 56%, rgba(236,218,157,1) 61%, rgba(212,178,57,1) 100%);
}
[data-theme="maroon-gold"] .radioButton:focus,
[data-theme="maroon-gold"] .radioButton:hover {
  border-top-color: #c6814a;
  border-left-color: #c6814a;
  border-bottom-color: #ffe0ad;
  border-right-color: #ffe0ad;
}
[data-theme="maroon-gold"].splide__slide:before {
  opacity: 0;
}
[data-theme="maroon-gold"].splide__slide {
  background-color: #4d031f;
  /* background: linear-gradient(130deg, #aa0000 10%, #ff0000 30%, #bb0000); */
}
