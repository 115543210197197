* {
  box-sizing: border-box;
}

html, body, button, input {
  font-size: 20px;
  font-family: 'Quicksand', sans-serif;
}

body {
  margin: 0;
}

.content-wrapper select {
  margin: 10px 0;
  padding: 10px;
}

.App {
  text-align: center;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-group button + button {
  margin-left: 20px;
}

button {
  color: #fff;
  padding: 20px;
  border-radius: 50px;
  border: 0;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 1.2rem;
}

.back-button {
  transform: rotate(180deg);
  position: absolute;
  top: 10px;
  left: 10px;
}

.proceed-button {
  border: 4px solid #4c7e1e;
  color: #fff;
  background-color: #4c7e1e;
}

.proceed-button:hover,
.proceed-button:focus {
  color:  #2c4a0f;
  background-color: #c7eda5;
}
.clearBtn {
  margin-left: 20px;
}
.stop-button,
.clearBtn {
  border: 4px solid #6e0a2b;
  color: #fff;
  background-color: #6e0a2b;  
}
.stop-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.stop-button:hover,
.stop-button:focus {
  color:  #380818;
  background-color: #f5bccf;
}

.isExploding {
  overflow: hidden;
}

.explode {
  animation: explodeShake infinite 100ms linear;
}

@keyframes explodeShake {
  from {
    transform: rotate(-1deg);
  }
  to {
    transform: rotate(1deg);
  }
}

.passIndicator {
  margin-top: 40px;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  background-color: rgb(255 107 107);
  box-shadow: 0px 0px 8px 0px rgb(255 0 0);
  display: inline-block;
  transition: box-shadow 150ms ease-out, background-color 150ms ease-out;
}

.passIndicator.hasPassed {
  background-color: #7cff7c;
  box-shadow: 0px 0px 15px 0px rgb(75 255 0);
  animation: throbbingLight infinite 3000ms ease-out;
}

@keyframes throbbingLight {
  0% {
    background-color: #7cff7c;
    box-shadow: 0px 0px 20px 5px rgb(75 255 0);
  }
  50% {
    background-color: #50ab50;
    box-shadow: 0px 0px 5px 0px rgb(75 255 0);
  }
  100% {
    background-color: #7cff7c;
    box-shadow: 0px 0px 20px 5px rgb(75 255 0);
  }
}

.element-invisible {
  position: absolute !important;
  height: 1px; width: 1px; 
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

@media screen and (max-width: 1024px) {

  .GameSelect > div {
    display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
  }

  .GameSelect .gameTitle {
    font-size: 10vh;
  }

  .clueCarouselWrapper .splide__slide>div {
    width: calc(100% - 40px);
    transform: translateX(-50%) scale(0.7);
    top: auto;
    bottom: 20px;
  }

  .clueCarouselWrapper .splide__slide.is-active>div {
    transform: translateX(-50%) scale(1);
  }

  .clueSlide img {
    width: 100%;
  }
  

  .radioButton {
    padding: 10px;
  }
  .radioWrapper {
    flex: auto;
    width: 100%;
  }
  
  .statusBar {
    bottom: auto;
    top: 0;
  }

  .statusBar > div:first-child {
    /* display: flex; */
    /* justify-content: end; */
    /* flex-direction: column; */
    width: 130px;
  }

  .statusBar > div:last-child {
    display: grid;
  }
  .statusBar span {
    padding-left: 0px;
  }

  .gameOver {
    background-size: 120% auto;
  }

}