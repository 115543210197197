.gameTitle {
  font-family: 'Qwigley', cursive;
}

.GameSelect {
  height: 100vh;
  background-image: url('../images/blurry-lights.jpg');
  background-size: cover;
}
.buttonWrapper {
  position: relative;
}
.GameSelect a {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin: 6px;
  padding: 20px;
  border: 3px solid #000;
  display: inline-block;
  width: 50%;
  max-width: 300px;
  border-radius: 60px;
  font-weight: bold;
  background: linear-gradient(130deg, #ffffff 10%, #eeeeee 30%, #ffffff);
  background: linear-gradient(130deg, rgba(255,0,0,0.5) 0%, rgba(255,194,0,0.5) 19%, rgba(245,255,0,0.5) 34%, rgba(184,225,11,0.5) 48%, rgba(0,255,21,0.5) 58%, rgba(0,255,252,0.5) 73%, rgba(0,14,255,0.5) 86%, rgba(235,0,255,0.5) 100%);
  background-size: 150% 100%;
  color: #000;
  text-align: center;
  text-decoration: none;
  transition: 
    border-top-color 300ms ease-out,
    border-left-color 300ms ease-out,
    border-bottom-color 300ms ease-out,
    border-right-color 300ms ease-out,
    background-position 300ms ease-out;
}
.GameSelect a:focus,
.GameSelect a:hover {
  background-position: top;
}
.GameSelect button {
  position: fixed;
  bottom: 0;
}
.GameSelect .gameTitle {
  text-align: center;
  font-size: 22vh;
  margin: 0;
  color: white;
  text-shadow: 0 0 12px black;
  font-weight: 100;
  padding: 100px 100px;
}